import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { isGuestMode } from 'helpers/cookie'

export interface ICommunityRecommendationParams {
  cat_id?: string | string[]
  page?: number
  limit?: number
}

export const getCommunityRecommendation = async ({
  cat_id,
  page,
  limit,
}: ICommunityRecommendationParams) => {
  let url = !isGuestMode()
    ? `/recommendation/v1/community`
    : `/recommendation/guest/v1/community`

  return await apiClient({
    url: url,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params: {
      page: page,
      limit: limit,
      cat_id: cat_id,
      sources: !isGuestMode() ? 'recommendation' : '',
    },
  })
}
