/* eslint-disable @next/next/no-img-element, react/no-children-prop */
import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Attention from 'components/commons/Attention'
import ModalDialog from '@mui/material/Dialog'
import {
  DialogTitle,
  Fab,
  IconButton,
  Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/CancelRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircularProgress from '@mui/material/CircularProgress'
import CoinIcon from 'assets/svg/dailyBonus/coin.svg'
import CloseIcon from '@mui/icons-material/Close'
import CoinCheck from 'assets/svg/dailyBonus/coin-check.svg'
import CoinMany from 'assets/svg/dailyBonus/coin-many.svg'
import authConfig from 'configs/auth'
import isEmpty from 'helpers/is-empty'
import { setWithExpiry, getWithExpiry } from 'helpers/local-storage'
import redirect from 'helpers/redirect'
import { redirectWithReferrer } from 'helpers/redirector'
import { useRouter } from 'next/router'
import { Refresh } from '@mui/icons-material'
import { getLastAuthUser } from 'helpers/cognito/token'
import { useTheme } from '@mui/material/styles'
import * as service from 'services/campaign'
import { getIdToken } from 'helpers/auth'
import StateImage from '../StateImage'
import Button from '../Button'

const DailyCheckInModal = styled(ModalDialog)<{
  iserror: string
}>(({ iserror, theme }) => ({
  '.MuiPaper-root': {
    padding: '8px 16px 20px',
    textAlign: 'center',
    backgroundImage:
      iserror === 'false' && 'url(/img/dailyBonus/background.png)',
    backgroundRepeat: iserror === 'false' && 'no-repeat',
    backgroundPosition: iserror === 'false' && '50%',
    backgroundSize: iserror === 'false' && 'cover',
    background:
      iserror === 'true' && theme.palette.background.secondary,
  },
}))

const ModalTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '24px',

  fontWeight: 700,
  letterSpacing: '0.0015em',
  lineHeight: '28px',
  marginTop: 16,
  padding: 0,
}))
const ModalDesc = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '16px',

  lineHeight: '24px',
  letterSpacing: '0.0015em',
  margin: '0 10%',
  marginBottom: 16,
  marginTop: 8,
}))

const ModalContent = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
})

const DailyBonusContainer = styled('div')({
  marginBottom: 16,
  width: '24%',
})

const DailyBonusCard = styled('div')({
  padding: '0 4%',
})

const DailyBonusContent = styled('div')<{
  status: string
}>(({ status, theme }) => ({
  background:
    status === 'done'
      ? theme.palette.background.primary
      : 'rgb(20, 18, 32, 0.5)',
  opacity: status === 'done' ? '0.7' : 'unset',
  borderRadius: 12,
  border: `1px solid ${theme.palette.orange[500]}`,
  padding: '12% 10%',
}))

const CoinWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  '>svg': {
    width: 'auto',
  },
})

const CoinLabel = styled('div')<{
  status: string
}>(({ status }) => ({
  fontWeight: 700,
  fontSize: 14,
  marginTop: 6,
  color: status === 'active' ? 'primary' : 'secondary',
}))

const LabelCard = styled(Typography)<{
  status: string
}>(({ status, theme }) => ({
  fontWeight: status === 'active' ? '700' : '400',
  opacity: status === 'active' ? 1 : 0.7,
  fontSize: 12,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  marginTop: 8,
}))

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  background: theme.palette.orange[500],
  display: 'block',
  '&:hover': {
    background: theme.palette.orange[500],
  },
}))

const StyleSuccessClaim = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '>p': {
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.blue[500],
    marginLeft: '6px',
  },
}))

const StyleLoader = styled('div')({
  lineHeight: '36px',
  svg: {
    color: 'white',
  },
})

const FloatingBtn = styled(Fab)({
  right: 0,
  transform: 'translateX(-50%)',
  position: 'fixed',
  bottom: '100px',
  cursor: 'pointer',
  '@media (min-width:480px)': {
    right: '10%',
  },
})

const DailyBonusBtn = styled('div')(({ theme }) => ({
  position: 'relative',
  '>img': {
    width: 58,
    height: 77,
  },
  '>svg': {
    top: '0px',
    right: '0px',
    color: theme.palette.tiptip[500],
    position: 'absolute',
  },
}))

function DailyBonus(): JSX.Element {
  const theme = useTheme()
  const { isReady } = useRouter()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const idToken = getIdToken()
  const lastAuthUser = getLastAuthUser()

  const [open, setOpen] = useState(false)
  const [openFloatingBtn, setOpenFloatingBtn] = useState(true)
  const [dailyBonusData, setDailyBonusData] = useState([])
  const [isLoadingDailyBonus, setLoadingDailyBonus] = useState(false)
  const [isLoadingClaim, setLoadingClaim] = useState(false)
  const [isSuccessClaim, setSuccessClaim] = useState(false)
  const [isFailedClaim, setFailedClaim] = useState(false)
  const [isErrorDailyBonus, setErrorDailyBonus] = useState(false)

  const [flagDailyBonus, setFlagDailyBonus] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const redirectURL = (url) => {
    redirect(url)
  }
  const handleClaimCoins = () => {
    if (!isEmpty(idToken)) {
      const claimID = dailyBonusData.find(
        (item) => item.status === 'active',
      )?.id
      claimDailyBonus(claimID)
    } else {
      redirectURL(
        redirectWithReferrer(authConfig.loginPath, true, false),
      )
    }
  }

  const getFlagDailyBonus = async () => {
    try {
      const response = await service.getCheckinFlag(idToken)
      if (response?.data?.code === 'SUCCESS') {
        setFlagDailyBonus(response.data.flag)
      }
    } catch (error) {}
  }

  const getDailyBonus = async (isGuest: boolean = false) => {
    setLoadingDailyBonus(true)
    try {
      const response = await service.getCheckinList(idToken)
      if (response?.data?.code === 'SUCCESS') {
        if (
          response?.data?.filter((item) => item.status === 'active')
            .length === 0
        ) {
          setSuccessClaim(true)
        }
        setLoadingDailyBonus(false)
        setErrorDailyBonus(false)

        const newData = [...response.data]

        if (isGuest && !!newData[0]) {
          newData[0].status = 'today'
        }

        setDailyBonusData(newData)
      }
    } catch (error) {
      setLoadingDailyBonus(false)
      setErrorDailyBonus(true)
    }
  }

  const claimDailyBonus = async (id: string) => {
    setFailedClaim(false)
    setLoadingClaim(true)
    try {
      const response = await service.postCheckinClaim(id, idToken)
      if (response?.data?.code === 'SUCCESS') {
        setSuccessClaim(true)
        setLoadingClaim(false)
        getDailyBonus()
        setFailedClaim(false)
      }
    } catch (error) {
      setLoadingClaim(false)
      setErrorDailyBonus(false)
      setFailedClaim(true)
    }
  }

  useEffect(() => {
    if (!isEmpty(idToken)) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [idToken, lastAuthUser])

  useEffect(() => {
    if (isReady && !isEmpty(idToken)) {
      getFlagDailyBonus()
    }
  }, [isReady, idToken])

  useEffect(() => {
    setOpenFloatingBtn(true) // Enable floating button by default
    if (isReady && !isEmpty(idToken)) {
      if (flagDailyBonus) {
        getDailyBonus()
      }
    } else {
      setOpenFloatingBtn(false) // Hide only if the user is not logged ins
      getDailyBonus(true)
    }
  }, [isReady, idToken, flagDailyBonus])

  useEffect(() => {
    if (isReady && isEmpty(getWithExpiry('dailyBonus'))) {
      const midnight = new Date().setHours(24, 0, 0, 0)
      setWithExpiry('dailyBonus', 'show', midnight)
      setOpen(true)
    }
  }, [isReady])

  return (
    <>
      {flagDailyBonus && (
        <DailyCheckInModal
          id="c-common-dailybonus-modal"
          onClose={handleClose}
          open={open}
          iserror={isErrorDailyBonus ? 'true' : 'false'}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 0,
              color: !isErrorDailyBonus
                ? theme.palette.text.primary
                : theme.palette.tiptip[500],
            }}
            id="b-common-dailybonus-button-close"
          >
            <CloseIcon />
          </IconButton>
          {!isLoadingDailyBonus && (
            <>
              <ModalTitle id="c-common-dailybonus-title-label">
                Bonus Check In Harian
              </ModalTitle>
              {!isErrorDailyBonus ? (
                <>
                  <ModalDesc id="c-common-dailybonus-description-label">
                    {isLoggedIn
                      ? 'Kembali lagi besok untuk mendapatkan coin lainnya'
                      : 'Masuk ke akun TipTip untuk mendapatkan koin gratis'}
                  </ModalDesc>
                  <ModalContent>
                    {dailyBonusData.map((item, index) => {
                      return (
                        <DailyBonusContainer
                          key={String(`dailybonus-card-${index}`)}
                        >
                          <DailyBonusCard>
                            <DailyBonusContent status={item.status}>
                              <CoinWrapper>
                                {item.status === 'done' ? (
                                  <CoinCheck
                                    sx={{ opacity: '0.7' }}
                                  />
                                ) : index ===
                                  dailyBonusData.length - 1 ? (
                                  <CoinMany />
                                ) : (
                                  <CoinIcon />
                                )}
                              </CoinWrapper>
                              <CoinLabel status={item.status}>
                                +{item.coin}
                              </CoinLabel>
                            </DailyBonusContent>
                            <LabelCard status={item.status}>
                              {isLoggedIn && <>{item.title}</>}

                              {/* For guest user, display as "Hari ini" for the first item */}
                              {!isLoggedIn &&
                                (index === 0
                                  ? 'Hari ini'
                                  : item.title)}
                            </LabelCard>
                          </DailyBonusCard>
                        </DailyBonusContainer>
                      )
                    })}
                  </ModalContent>

                  {!isFailedClaim && (
                    <>
                      {!isLoadingClaim ? (
                        isSuccessClaim ? (
                          <StyleSuccessClaim>
                            <CheckCircleIcon
                              sx={{
                                fontSize: '20px',
                                color: '#51CE83',
                              }}
                            />
                            <Typography
                              style={{
                                color:
                                  theme.palette.background.primary,
                              }}
                            >
                              Coin diterima
                            </Typography>
                          </StyleSuccessClaim>
                        ) : (
                          <StyledButton
                            id={
                              isLoggedIn
                                ? 'b-common-dailybonus-button-getcoin'
                                : 'b-common-dailybonus-button-signin'
                            }
                            type="submit"
                            variant="contained"
                            disableElevation
                            fullWidth
                            onClick={() => handleClaimCoins()}
                          >
                            {isLoggedIn
                              ? 'Ambil Coin'
                              : 'Masuk Sekarang'}
                          </StyledButton>
                        )
                      ) : (
                        <StyleLoader>
                          <CircularProgress
                            style={{ width: '20px', height: '20px' }}
                          />
                        </StyleLoader>
                      )}
                    </>
                  )}

                  {isFailedClaim && (
                    <div
                      style={{
                        marginTop: 38,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span
                        style={{
                          color: theme.palette.text.secondary,
                          fontSize: 12,
                          lineHeight: '16px',
                        }}
                      >
                        Terjadi kesalahan
                      </span>
                      <span
                        style={{
                          marginLeft: 8,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onClick={() => handleClaimCoins()}
                      >
                        <span
                          style={{
                            color: theme.palette.text.primary,
                            fontWeight: 700,
                            fontSize: 12,
                            lineHeight: '16px',
                            textDecoration: 'underline',
                          }}
                        >
                          Coba lagi
                        </span>
                        <Refresh
                          htmlColor={theme.palette.text.primary}
                          fontSize="small"
                          style={{ marginLeft: 4 }}
                        />
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <Attention
                  padding="20px"
                  children={<StateImage type="error" />}
                  title="Gagal menampilkan halaman"
                  desc="Terjadi kesalahan dalam memuat halaman, mohon refresh untuk memuat ulang"
                  textBtnFirst="Refresh"
                  handleRedirectBtnFirst={() => {
                    getDailyBonus()
                  }}
                />
              )}
            </>
          )}
        </DailyCheckInModal>
      )}
      {flagDailyBonus && !isLoadingDailyBonus && openFloatingBtn && (
        <FloatingBtn
          aria-label="daily bonus"
          id="b-common-dailybonus-floating-button"
        >
          <DailyBonusBtn>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              id="b-common-dailybonus-floating-close"
              src="/img/dailyBonus/floating-bonus-button.png"
              onClick={() => {
                setOpen(true)
              }}
            />
            <CancelIcon
              onClick={() => {
                setOpenFloatingBtn(false)
              }}
              fontSize="small"
            />
          </DailyBonusBtn>
        </FloatingBtn>
      )}
    </>
  )
}

export default DailyBonus
