import Attention from 'components/commons/Attention/v2'
import StateImage from 'components/commons/StateImage'
import { productType } from 'constants/common'

type IEmptyStateAttention = {
  status?: productType
  t?: any
}
function EmptyStateAttention({ status, t }: IEmptyStateAttention) {
  const renderSentence = (status) => {
    let title = ''
    switch (status) {
      case 'community':
        title = 'Komunitas'
        break
      case 'content':
        title = 'Konten Digital'
        break
      case 'session':
        title = 'Sesi'
        break
      case 'event':
        title = 'Event'
        break
      default:
        title = 'Konten'
        break
    }
    return title
  }
  const renderTitleAttention = () => {
    return {
      title: `Belum ada ${renderSentence(status)} saat ini`,
      subtitle: t('emptyExploreSessionDesc'),
      renderButton: false,
    }
  }
  return (
    <Attention
      image={<StateImage type="empty-search" />}
      title={renderTitleAttention().title}
      subtitle={renderTitleAttention().subtitle}
    />
  )
}

export default EmptyStateAttention
