import { styled } from '@mui/material'
import {
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import Attention from 'components/commons/Attention/v2'
import { ButtonAttention } from 'components/commons/Attention/v2/styled'
import Box from 'components/commons/Box'
import LoadingButton from 'components/commons/LoadingButton'
import StateImage from 'components/commons/StateImage'
import CommunityCard from 'components/domains/Community/CommunityCard'
import { getErrorMessage } from 'helpers/error'
import { sendLog } from 'helpers/log'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getCommunityRecommendation } from 'services/community'
import { useCommunityRecommendationStore } from 'stores/domains/Recommendation'
import EmptyStateAttention from '../EmptyStateAttention'
import ExploreSkeleton from '../ExploreSkeleton'

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    gap: '8px',
    gridTemplateColumns: 'repeat(2, minmax(168px, 265px))',
  },
  gridTemplateColumns: 'repeat(6, minmax(100px, 300px))',
}))

const CommunityTab = () => {
  const { t } = useTranslation()
  const { isReady } = useRouter()
  const DEFAULT_LIMIT = 10
  const queryClient = useQueryClient()

  const {
    communityRecommendation,
    isError,
    setCommunityRecommendation,
    clearError,
    setError,
  } = useCommunityRecommendationStore((state) => ({
    setCommunityRecommendation: state.setCommunityRecommendation,
    communityRecommendation: state.communityRecommendation,
    isError: state.isError,
    clearError: state.clearError,
    setError: state.setError,
  }))

  const loadCommunity = async (page: number) => {
    clearError()

    try {
      const response = await getCommunityRecommendation({
        page: page,
        limit: DEFAULT_LIMIT,
      })
      setCommunityRecommendation(response.data)
      return response
    } catch (error) {
      setError(getErrorMessage(error))
      sendLog(error)
    }
  }

  const fetchCommunities = async ({ pageParam = 1 }) => {
    const rest = await loadCommunity(pageParam)
    return rest?.data || []
  }

  const {
    data,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['communities_recommendation'],
    fetchCommunities,
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage || !lastPage._metadata) return
        return lastPage?._metadata.page + 1
      },
      enabled: false,
    },
  )

  const clearData = () => {
    queryClient.removeQueries(['communities_recommendation'])
  }

  const communities = useMemo(() => {
    return data?.pages.map(({ data }) => data).flat() ?? []
  }, [data])

  useEffect(() => {
    if (isReady) {
      clearData()
      refetch()
    }
  }, [isReady])

  if (isFetching && !isFetchingNextPage) {
    return <ExploreSkeleton />
  }

  if (isError) {
    return (
      <Attention
        title="Gagal menampilkan halaman"
        subtitle="Mohon refresh halaman atau coba lagi dalam beberapa saat"
        image={<StateImage type="error" />}
        actions={[
          <ButtonAttention
            id="b-home-error-event"
            key="b-home-error-event"
            onClick={() => {}}
          >
            Refresh
          </ButtonAttention>,
        ]}
      />
    )
  }

  if (communities.length === 0) {
    return <EmptyStateAttention status={'community'} t={t} />
  }

  return (
    <>
      <Container>
        {communities?.map((item) => (
          <CommunityCard
            community={item}
            key={`community-${item.mask_id}`}
          />
        ))}
      </Container>
      {communityRecommendation?.loadMore && (
        <LoadingButton
          buttonVariant="ghost"
          sx={{ width: '152px', height: 44, margin: 'auto', mt: 8 }}
          onClick={() => {
            fetchNextPage()
          }}
          loading={isFetchingNextPage}
        >
          {t('showAll')}
        </LoadingButton>
      )}
    </>
  )
}

export default CommunityTab
